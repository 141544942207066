import CryptoJS from 'crypto-js';
import getConfig from 'next/config';
import { generateComponentsData } from '../components/PageBuilder/helper';
import { getSessionData, SKEY_COOKIE_KEY } from './api';
import {
  getTranslations,
  jsonTranslate,
} from '../services/translation.service';
import { staticTranslations } from './staticTranslations';

export const decryptSessionData = (str) => {
  try {
    const {
      publicRuntimeConfig: { nextDataSecretKey },
    } = getConfig();
    const bytes = CryptoJS.AES.decrypt(str, nextDataSecretKey);
    return JSON.parse(bytes.toString(CryptoJS.enc.Utf8)) || {};
  } catch (e) {
    return {};
  }
};

export const encryptSessionData = (data) =>
  CryptoJS.AES.encrypt(
    JSON.stringify(data),
    process.env.PR_NEXT_DATA_SECRET_KEY,
  ).toString();

export const getDotKeys = (obj, parentKey = '') => {
  let keys = [];

  // eslint-disable-next-line guard-for-in,no-restricted-syntax
  for (const key in obj) {
    const dotKey = parentKey ? `${parentKey}.${key}` : key;

    if (typeof obj[key] === 'object') {
      const childKeys = getDotKeys(obj[key], dotKey);
      keys = [...keys, ...childKeys];
    } else if (dotKey.includes('.')) {
      keys.push(dotKey);
    }
  }

  return keys;
};

export const getSSRPageDataProps = async (req, rawPageData) => {
  let pageData = null;
  let translations = {};
  const sessionData = await getSessionData(req);

  if (rawPageData?.page) {
    const { page, components = [] } = rawPageData;
    pageData = {
      ...page,
      components: generateComponentsData(page.id, components),
    };
  }

  if (pageData) {
    // eslint-disable-next-line no-use-before-define
    pageData = await jsonTranslate(
      pageData,
      sessionData?.customersession?.language || 'en',
    );
    if (!sessionData?.translations) {
      translations = await getTranslations(staticTranslations);
    }
  }

  return {
    sessionData: {
      ...sessionData,
      pageData,
      translations,
    },
    sessionId: req?.cookies?.[SKEY_COOKIE_KEY],
  };
};

export const isNumeric = (n) =>
  /^[-+]?(?:\d+(?:\.\d*)?|\.\d+)$/.test(String(n));

export const formSubmitTypeReplace = (obj) => {
  delete obj?.tmp;
  delete obj?.store;
  delete obj?.launch;
  delete obj?.offers;
  Object.entries(obj).forEach(([key, value]) => {
    if (value === '') {
      obj[key] = null;
    } else if (['true', 'false'].includes(value)) {
      obj[key] = value === 'true';
    } else if (
      value !== null &&
      typeof value === 'object' &&
      !Array.isArray(value)
    ) {
      formSubmitTypeReplace(value);
    }
  });
  return obj;
};
