const { doProxyRequest } = require('../utils/api');

const getTranslations = async (translationsKeys = []) => {
  try {
    if (translationsKeys?.length === 0) {
      return {};
    }
    const translationsRes = await doProxyRequest({
      url: '/translations',
      method: 'GET',
      params: {
        search: {
          trans_key: {
            $in: translationsKeys,
          },
        },
        limit: 1000,
      },
    });

    if (translationsRes?.rows?.length) {
      return translationsRes.rows.reduce((acc, item) => {
        acc[item.transKey] = {
          en: item.en && item.en.replaceAll('"', '\\"'),
          es: item.es && item.es.replaceAll('"', '\\"'),
        };
        return acc;
      }, {});
    }
  } catch (e) {
    return {};
  }
};

const stringTranslate = async (text, language = 'en') => {
  try {
    if (!text?.includes('translate(')) {
      return text;
    }

    const translateKeys = [];
    const regex = /translate\((\w+)\)/g;
    let match;

    // eslint-disable-next-line no-cond-assign
    while ((match = regex.exec(text)) !== null) {
      translateKeys.push(match[1]);
    }

    if (translateKeys.length > 0) {
      const translationMap = await getTranslations(translateKeys);

      return text.replace(regex, (match, key) => {
        if (!translationMap[key][language]) {
          return match;
        }
        return translationMap[key][language];
      });
    }
  } catch (e) {
    return text;
  }
};

const jsonTranslate = async (object, language = 'en') => {
  try {
    const translatedString = await stringTranslate(
      JSON.stringify(object),
      language,
    );
    return JSON.parse(translatedString);
  } catch (e) {
    return object;
  }
};

module.exports = {
  jsonTranslate,
  stringTranslate,
  getTranslations,
};
