import PropTypes from 'prop-types';
import { v4 as uuidv4 } from 'uuid';

export const defaultComponentFieldPropType = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  company: PropTypes.string,
  properties: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  label: PropTypes.string,
  value: PropTypes.string,
  type: PropTypes.string,
};

export const defaultComponentPropType = {
  id: PropTypes.string.isRequired,
  pageId: PropTypes.string.isRequired,
  containerId: PropTypes.string,
  ajaxId: PropTypes.string,
  fieldId: PropTypes.string,
  sequence: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  tab: PropTypes.string,
  tooltip: PropTypes.string,
  label: PropTypes.string,
  required: PropTypes.object,
  math: PropTypes.object,
  size: PropTypes.string.isRequired,
  image: PropTypes.string,
  enabled: PropTypes.object,
  display: PropTypes.object,
  classes: PropTypes.string,
  value: PropTypes.string,
  properties: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
};

export const defaultPagePropType = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  headerText: PropTypes.string,
  preText: PropTypes.string,
  postText: PropTypes.string,
  timeoutType: PropTypes.string,
  salesFacing: PropTypes.bool,
  navigationType: PropTypes.string,
  navProperties: PropTypes.object,
};

export const arrayToTree = (array, parentProperty, childrenProperty) => {
  const index = () => {
    // eslint-disable-next-line no-unused-vars
    const empty = (_) => new Map();
    const update = (t, k, f) => t.set(k, f(t.get(k)));
    const append = (t, k, v) => update(t, k, (a = []) => [...a, v]);
    // eslint-disable-next-line default-param-last
    const _new = (a = [], f) => a.reduce((t, v) => append(t, f(v), v), empty());
    return { empty, update, append, new: _new };
  };
  const graph = () => {
    // eslint-disable-next-line no-unused-vars
    const empty = (_) => {};
    const _new = (i, f, root = null) => {
      // eslint-disable-next-line no-use-before-define
      const many = (a = []) => a.map((v) => one(v));
      const one = (v) => f(v, (next) => many(i.get(next)));
      return many(i.get(root));
    };
    return { empty, new: _new };
  };

  return graph().new(
    index().new(array, (node) => node?.[parentProperty]),
    (node, children) => ({
      ...node,
      children: children(node?.[childrenProperty]),
    }),
  );
};

export const generateComponentsData = (
  pageId,
  components = [],
  containerId = null,
  sequence = 0,
) => {
  let resultComponentData = [];
  for (let i = 0; i < components.length; i++) {
    const component = components[i];
    const componentId = uuidv4();
    const { children, ...componentProps } = component;

    resultComponentData.push({
      id: componentId,
      sequence: sequence + i,
      pageId,
      containerId,
      ...componentProps,
    });

    if (children) {
      resultComponentData = [
        ...resultComponentData,
        ...generateComponentsData(
          pageId,
          children,
          componentId,
          sequence + 1,
          resultComponentData,
        ),
      ];
    }
  }
  return resultComponentData;
};

/**
 *
 * @param str
 * @param pattern
 * @returns {string}
 * Pattern format
 * '9': '[0-9]',
 * 'a': '[A-Za-z]',
 * '*': 'any'
 */
export const replacePatternWithValues = (str, pattern) => {
  let result = '';
  const maskRegexMap = {
    9: /([0-9])/g,
    a: /([a-zA-z-])/g,
    '*': /(.)/g,
  };
  for (let i = 0; i < pattern.length; i++) {
    if (str[i]) {
      if (['9', 'a', '*'].includes(pattern[i])) {
        const regex = new RegExp(maskRegexMap[pattern[i]]);
        if (regex.test(str[i])) {
          result += str[i];
        }
      } else if (pattern[i] === str[i]) {
        result += pattern[i];
      } else {
        result = replacePatternWithValues(
          `${result}${pattern[i]}${str[i]}`,
          pattern,
        );
      }
    }
  }

  return result;
};

export const numberWithCommas = (val) => {
  if (!['string', 'number'].includes(typeof val)) {
    return '';
  }

  return (
    val
      .toString()
      .replaceAll(',', '')
      // eslint-disable-next-line prefer-regex-literals
      .replace(new RegExp('(\\d)(?=(\\d{3})+\\b)', 'g'), '$1,')
  );
};
