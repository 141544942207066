import React, { useMemo, useState } from 'react';
import Head from 'next/head';
import DefaultWhitelabel from '../../utils/whitelabels/default';
import LoadingPage from '../LoadingPage/LoadingPage';
import Favicon from '../../assets/images/favicon.ico';
import { decryptSessionData } from '../../utils/helper';

const whitelabels = {
  default: DefaultWhitelabel,
};

let currentContext = null;

export const WhitelabelWrapper = React.createContext();
export const WhitelabelContext = React.createContext();
export const DataContext = React.createContext();

function DefaultLayout(props) {
  const { children, whitelabel } = props;
  const [pageTitle, setPageTitle] = useState('LendPro Application');
  const [isLoading, setIsLoading] = useState(false);
  const [sessionData, setSessionData] = useState(
    decryptSessionData(props?.sessionData),
  );

  const dataContextProviderValue = useMemo(
    () => ({
      sessionData,
      setSessionData,
    }),
    [sessionData],
  );

  return (
    <DataContext.Provider value={dataContextProviderValue}>
      <WhitelabelWrapper.Provider value={whitelabel || 'default'}>
        <WhitelabelWrapper.Consumer>
          {(whitelabel) => {
            if (!currentContext) {
              currentContext = new whitelabels[whitelabel]();
            }
            currentContext.isLoading = isLoading;
            currentContext.setIsLoading = setIsLoading;
            currentContext.pageTitle = pageTitle;
            currentContext.setPageTitle = setPageTitle;
            return (
              <WhitelabelContext.Provider value={currentContext}>
                <Head>
                  <link rel="icon" href={Favicon.src} />
                  <title>{pageTitle}</title>
                  <meta
                    name="viewport"
                    content="initial-scale=1.0, width=device-width"
                  />
                </Head>
                {isLoading && <LoadingPage />}
                <div id="load-overlay" style={{ display: 'none' }}>
                  {!isLoading && children}
                </div>
              </WhitelabelContext.Provider>
            );
          }}
        </WhitelabelWrapper.Consumer>
      </WhitelabelWrapper.Provider>
    </DataContext.Provider>
  );
}

DefaultLayout.getInitialProps = async (ctx) => ({
  whitelabel: ctx.query.whitelabel,
});

export default DefaultLayout;
