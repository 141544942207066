import React from 'react';
import Spinner from 'react-bootstrap/Spinner';

import styles from './LoadingPage.module.scss';

function LoadingPage() {
  return (
    <div className={styles.fullScreen}>
      <Spinner animation="border" variant="primary" />
    </div>
  );
}

LoadingPage.propTypes = {};

export default LoadingPage;
