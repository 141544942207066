class DefaultWhitelabel {
  constructor() {
    this.templateSizes = {
      'xxx-small': 'col-1',
      'xx-small': 'col-3 col-md-2',
      'x-small': 'col-6 col-md-4 col-lg-3',
      small: 'col-12 col-sm-6 col-md-4',
      'small-centered': 'col-12 offset-sm-3 col-sm-6 offset-md-4 col-md-4',
      'small-pair-left': 'col-12 col-sm-6 offset-md-1 col-md-4',
      'small-pair-right': 'col-12 col-sm-6 offset-md-2 col-md-4',
      medium: 'col-12 col-md-8 col-lg-6',
      'medium-centered': 'col-12 offset-md-2 col-md-8 offset-lg-3 col-lg-6',
      large: 'col-12 col-md-10 col-lg-7',
      'large-third': 'col-12 col-lg-4',
      larger: 'col-12 col-lg-8',
      'x-large': 'col-12 col-lg-9',
      'xx-large': 'col-12 col-lg-10',
      'xxx-large': 'col-12 col-lg-11',
      'half-width': 'col-12 col-md-6',
      'full-width': 'col-12',
      col01: 'col-1',
      col02: 'col-2',
      col03: 'col-3',
      col04: 'col-4',
      col05: 'col-5',
      col06: 'col-6',
      col07: 'col-7',
      col08: 'col-8',
      col09: 'col-9',
      col10: 'col-10',
      col11: 'col-11',
      col12: 'col-12',
      'col-12-x-3-x': 'col-12 col-md-3',
      'col-12-x-4-x': 'col-12 col-md-4',
      'col-12-x-5-x': 'col-12 col-md-5',
      'col-12-x-7-x': 'col-12 col-md-7',
      'col-6-md-2': 'col-6 col-md-2',
      'col-12-md-8-lg-8': 'col-12 col-md-8 col-lg-8',
    };

    this.isLoading = false;
  }

  setIsLoading() {}
}

export default DefaultWhitelabel;
