const axios = require('axios');

const SKEY_COOKIE_KEY = 'sKey';

const setSessionCookie = (res, sessionId, container) => {
  res.cookie(SKEY_COOKIE_KEY, sessionId, {
    maxAge: container.$config.$sessionKeyAge,
    httpOnly: true,
    secure: true,
    sameSite: 'none',
  });
};

const doProxyRequest = async (reqData) =>
  axios
    .post(`${process.env.PR_BASE_URL}/api/proxy`, reqData)
    .then((res) => {
      if (!res?.data || res?.data?.error) {
        return null;
      }
      return res.data;
    })
    .catch(() => null);

const getSessionData = async (req) => {
  let responseData = {};
  try {
    const {
      data: { error, data = null },
    } = await axios.get(`${process.env.PR_BASE_URL}/api/process`, {
      headers: req.headers,
    });

    if (error) {
      responseData.error = error;
    } else {
      responseData = data;
    }
  } catch (e) {
    responseData.error = e.message;
  }

  return responseData;
};

const geLenderLanguage = async (lenderId, language) => {
  if (!lenderId || !language) {
    return null;
  }

  return doProxyRequest({
    url: `/lender-language/${lenderId}/${language}`,
  });
};

const getResponseBranding = async (merchantId, language) => {
  if (!merchantId || !language) {
    return null;
  }
  return doProxyRequest({
    url: `/response-branding/${merchantId}/${language}`,
  });
};

const getLender = async (lenderId) => {
  if (!lenderId) {
    return null;
  }

  return doProxyRequest({
    url: `/lender/${lenderId}`,
  });
};

const getStore = async (storeId) => {
  if (!storeId) {
    return null;
  }

  return doProxyRequest({
    url: `/store/${storeId}`,
  });
};

const searchLaunch = async (launchCode) => {
  if (!launchCode) {
    return null;
  }

  return doProxyRequest({
    url: `/launch`,
    params: {
      search: {
        launchCode,
      },
    },
  });
};

module.exports = {
  SKEY_COOKIE_KEY,
  setSessionCookie,
  doProxyRequest,
  getSessionData,
  geLenderLanguage,
  getResponseBranding,
  getLender,
  getStore,
  searchLaunch,
};
